@import "./variables.scss";

* {
  box-sizing: border-box;
}

.revit-app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: stretch;
}

/*
.revit-app-container:lang(ja) {
  font-family: "MS Gothic";
}

.revit-app-container:lang(zh-Hans) {
  font-family: "Microsoft YaHei";
}

.revit-app-container:lang(zh-Hant) {
  font-family: "MingLiU";
}

.revit-app-container:lang(ko) {
  font-family: "Batang";
}
*/

.display-component-service {
  flex-grow: 1;
  height: 1em;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
}

.display-content-view {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  padding-right: 15px;
  background-color: $basics-colors-surfaceLightGrayLevel100;
  min-width: 0;
}

.display-sidebar {
  margin-left: 25px;
}

.display-with-nav {
  display: flex;
  flex-flow: row nowrap;
}

.display-top-nav {
  margin-top: 10px;
  min-height: min-content;
}

.display-upload-area {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.display-search-area {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex-grow: 4;
  flex-shrink: 0;
  min-width: 65%;
  margin-left: 10px;

  & > * {
    margin-right: 10px;
  }

  & > :last-child {
    margin-right: 0;
  }
}

.display-navigation-controls {
  display: flex;
}

.hig__flyout-v1__panel {
  overflow-y: visible !important;
}

// Style scrollbar with HIG theme.
::-webkit-scrollbar {
  width: 13px;
  height: 13px;
}

::-webkit-scrollbar-thumb {
  width: 5px;
  min-height: 36px;
  border: 4px solid transparent;
  border-radius: 10px;
  background-clip: padding-box;
  background-color: $basics-colors-charcoal200;
  &:hover {
    background-color: $basics-colors-charcoal500;
  }
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}
