@import "./variables.scss";

.dialog-footer {
  flex-shrink: 0;
  width: 100%;
  padding: 10px 15px;
  background-color: $colorScheme-surfaceLevel100Color;
  border-top: 1px solid $basics-colors-surfaceLightGrayLevel300;
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
  overflow: hidden;
  align-items: center;

  &__help-link {
    justify-self: flex-start;
    flex: 1;

    > a {
      font-size: 15px;
      color: $colorScheme-accentColor;

      &:hover {
        color: red;
      }
    }
  }
}

.dialog-footer-load-button {
  width: 150px;
  padding-right: 15px;
}

.dialog-footer-cancel-button {
  width: 135px;
}
