.display-filters-main {
  flex-shrink: 0;
  width: 100%;
  display: flex;
  flex-direction: column;

  & > * {
    margin-right: 20px;
  }
}
