@import "../variables.scss";

.navigator-ul
{
  list-style-type: none;
  margin-block-start: 0px;
  margin-block-end: 0px;
  padding-inline-start: 30px;
  margin-left: -10px;
}

.navigator-ul-root {
  list-style-type: none;
  margin-block-start: 0px;
  margin-block-end: 0px;
  padding-inline-start: 0px;
  font-size: 15px;
}

.navigator-item-selected {  
  width: fit-content;
  white-space: nowrap;
  background-color: $colorScheme-background-selected;
  &:focus {
    padding-left: 2px;
    padding-right: 2px;
  }
}

.navigator-item {
  width: fit-content;
  white-space: nowrap;
  &:hover {
    cursor: pointer;
    color: $textLink-primary-hover-fontColor;
    text-decoration: underline;
  }
  &:focus {
    padding-left: 2px;
    padding-right: 2px;
  }
}
