@import "../variables.scss";

.sidebar-container {
  width: 300px;
  height: 100%;  
  background-color: $colorScheme-surfaceLevel200Color;
  display: flex;
  flex-flow: column nowrap;
  padding: 0px 15px;
}

.sidebar-filter-label {
  font-size: 15px;
  line-height: 16px;
  padding-bottom: 5px;
  border-bottom: 1px solid $basics-colors-surfaceLightGrayLevel300;
  margin: 16px 0px;
}
