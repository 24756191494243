
@import "../variables.scss";

$item-border-hover: darkgrey;

// loading spinner overlay
.display-content-loading-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
}

.display-content-loading-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
}

.display-content-grid {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background-color: $colorScheme-surfaceLevel100Color;

  display: grid;
  grid-template-columns: repeat(auto-fill, $grid-column-size);
  grid-auto-rows: min-content;
  grid-gap: $grid-gap;
  padding: $grid-padding;
  justify-content: space-between;
}

.display-scrollable-div {
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  user-select: none;
}

.display-content-item-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 2.8em;
  -webkit-line-clamp: 2;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;  
}

.display-content-item-list-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  padding: 16px 0px 0px 5px;
}

.display-content-item-title-selected {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 2.8em;
  -webkit-line-clamp: 2;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  opacity: 0.4;
}

.display-content-table {
  height: 100%;

  .display-content-preview-item {
    border: 1px solid $basics-colors-surfaceLightGrayLevel300;
  }

  .display-content-actions {
    display: none;
  }

  .hig__table__row {
    &:hover .display-content-actions {
      display: initial;
    }
  }

  .hig__table__table-main .hig__table__row-column:first-child {
    padding-left: 10px;
  }

  .hig__table__table-main .hig__table__row-column:last-child {
    padding-right: 10px;
  }
}

.display-content-grid-item {
  display: flex;
  flex-flow: column nowrap;
  word-wrap: break-word;
  font-size: 13px;
}

.display-content-preview-load {
  position: relative;
  border-radius: 4px;
  border: 2px solid $basics-colors-surfaceLightGrayLevel300;

  & > .display-content-preview-item {
    height: 100%;
    width: 100%;
  }

  &:hover {
    border-color: $item-border-hover;

    & > .display-content-preview-item {
      opacity: 0.2;
    }

    & > .display-checkbox-hover {
      display: block;
      position: absolute;
    }
  }
}

.display-checkbox-hover {
  display: none;
}

.display-checkbox-checked {
  display: block;
  position: absolute;
}

.display-content-item-list-container {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 5px 0px;
  position: relative;

  &:hover {
    & >.display-checkbox-hover {
      display: block;
      position: absolute;
    }
  }
}

.display-content-item-list-selected {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 5px 0px;
  position: relative;
  background-color: $colorScheme-background-selected;
}

.display-content-preview-load-selected {
  position: absolute;
  background-color: $colorScheme-background-selected;
  z-index: 1;
  width: 100px;
  height: 100px;
}

@media screen and (max-width: 760px) {
  .display-content-grid {
    padding-left: 10px;
  }
}
