.display-search-box {
  width: auto;
  flex: 1;
  margin-top: 0;
}

.display-search-box-area {
  width: 100%;
}

input {
  font-size: 13px;
  font-family: 'ArtifaktElement';
}

input::placeholder {
  font-style: italic;
}
