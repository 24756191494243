.progress-bar {
    align-items: center;
    padding-top: 100px;
    font-size: 15px;
    font-weight: bold;
  }

.progress-cancel-button {
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 150px;
  }

.failed-item-list {
    font-weight: bold;
}