@import "../variables.scss";

.display-tree-nodes {
  overflow: auto;
  order: 2;
  flex-grow: 1;
  outline: 0;
}

.display-tree-label {
  order: 1;
  font-size: 15px;
  line-height: 16px;

  > h4 {
    border-bottom: 1px solid $basics-colors-surfaceLightGrayLevel300;
    padding-bottom: 5px;
    margin: 16px 0px;
  }
}
