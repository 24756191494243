.display-items-count {
  flex-shrink: 0;
  font-size: 13px;
  margin-top: 10px;
  margin-left: 5px;
}

.display-items-breadcrumbs {
  font-size: 13px;
  margin-top: 10px;
  margin-left: 5px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* Beginning of string */
  direction: rtl;
  text-align: left;
}

.display-deselect-button {
  flex-shrink: 0;
}
  